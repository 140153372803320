import React, { memo } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { useSelector } from 'react-redux';
import { drawSeriesDailyTypeGraph } from '../../selectors/revenue';
import ReactApexChart from 'react-apexcharts';

import CenterBox from '../Common/CenterBox';
import colors from '../CommonStyle/colors';
import { useTranslation } from 'react-i18next';

const TypesGraph = ({ dateRange, loading }) => {
  const { t } = useTranslation();

  const dataGraph = useSelector(drawSeriesDailyTypeGraph(dateRange, t));

  const dailyGraph = {
    series: dataGraph.series,
    options: {
      chart: {
        toolbar: 'false',
        type: 'line',
        stacked: false,
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: 'dddd, dd MMM yyyy',
        },
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 10,
          offsetX: 20,
        },
      },
      dataLabels: {
        enabled: !1,
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: colors.dark,
          },
        ],
      },
      yaxis: dataGraph.series.map((data, index) => ({
        seriesName: data.name,
        max: parseInt(dataGraph.max),
        min: parseInt(dataGraph.min),
        labels: {
          style: {
            colors: data.color,
          },
          formatter: (val, index) => (val ? `${val.toFixed(2)} €` : '0 €'),
        },
        show: index === 0,
      })),
    },
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4 float-sm-left">
            {t('Revenues by Ad Type')}
          </CardTitle>
          <div className="clearfix"></div>
          {!loading ? (
            <div id="line-chart" className="apex-charts" dir="ltr">
              <ReactApexChart
                series={dailyGraph.series}
                options={dailyGraph.options}
                type="line"
                height={290}
              />
            </div>
          ) : (
            <CenterBox height={290}>
              <i className="bx bx-hourglass bx-spin font-size-32 text-secondary"></i>
            </CenterBox>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default memo(TypesGraph);
