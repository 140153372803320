import React, { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Collapse,
  Button,
} from 'reactstrap';
import { Form, Input } from '../../lib/availity/form';
import { Select } from '../../lib/availity/select';
import FormErrorMessage from '../../components/Common/FormErrorMessage';
import formValidation from './formValidation';

import * as usersActions from '../../store/users/actions';
import * as countriesActions from '../../store/countries/actions';
import * as networksActions from '../../store/networks/actions';
import * as publishersActions from '../../store/publishers/actions';
import * as customersActions from '../../store/customers/actions';
import { userEmailInUse } from '../../selectors/user';
import { selectCustomersWithoutPublisher } from '../../selectors/customer';
import { selectPublishersWithCustomer } from '../../selectors/publisher';
import { useDispatch, useSelector } from 'react-redux';

import firebase from 'firebase/app';
import assignAllowedRoles from '../../config/assignAllowedRoles';
import roles from '../../config/roles';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import { generateRandomString } from '../../helpers/sharedFunction';

const FormUser = (props) => {
  const editingUser = props.user || {};

  const user = useSelector((state) => state.Auth.admin);
  const allowedRoles = assignAllowedRoles[user.role];
  const emailsInUse = useSelector(userEmailInUse(editingUser.id));

  const countries = useSelector((state) => state.Country.countries);
  const networks = useSelector((state) => state.Network.networks);
  const publishers = useSelector((state) => state.Publisher.publishers);
  const customers = useSelector(selectCustomersWithoutPublisher);
  const publisherCustomers = useSelector(selectPublishersWithCustomer);
  const publisherCustomerIds = publisherCustomers.map(({ id }) => id);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(countriesActions.syncCountries());
    dispatch(networksActions.syncNetworks());
    dispatch(publishersActions.syncPublishers());
    dispatch(customersActions.syncCustomers());
  }, []);

  const handleSubmit = (values) => {
    const editValues = {
      id: editingUser.id,
      ...values,
      ...(values.role !== roles.PUBLISHER && {
        publisherId: firebase.firestore.FieldValue.delete(),
      }),
      ...(values.role !== roles.CUSTOMER && {
        customerId: firebase.firestore.FieldValue.delete(),
      }),
    };
    dispatch(
      props.create
        ? usersActions.createUser(values)
        : usersActions.updateUser(editValues),
    );
    props.closeModal();
  };

  const handleGeneratePassword = useCallback((setFieldValue) => {
    const password = generateRandomString();
    setFieldValue('password', password, false);
    setFieldValue('confirmPassword', password, false);
  }, []);

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={editingUser}
          validationSchema={formValidation(
            props.create,
            emailsInUse,
            publisherCustomerIds,
          )}
          onSubmit={handleSubmit}>
          {({ isValid, values, setFieldValue }) => (
            <>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="firstName">First Name</Label>
                    <Input type="text" name="firstName" id="firstName" />
                    <FormErrorMessage name="firstName" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="lastName">Last Name</Label>
                    <Input type="text" name="lastName" id="lastName" />
                    <FormErrorMessage name="lastName" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="displayName">Display Name</Label>
                    <Input type="text" name="displayName" id="displayName" />
                    <FormErrorMessage name="displayName" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="email">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      disabled={!props.create}
                    />
                  </FormGroup>
                  <FormErrorMessage name="email" />
                </Col>
                {props.create && (
                  <>
                    <Col md="2">
                      <FormGroup>
                        <Label htmlFor="password">Password</Label>
                        <Input type="password" name="password" id="password" />
                      </FormGroup>
                      <FormErrorMessage name="password" />
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <Label htmlFor="confirmPassword">
                          Confirm Password
                        </Label>
                        <Input
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                        />
                      </FormGroup>
                      <FormErrorMessage name="confirmPassword" />
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>Generate Random Password</Label>
                        <Button
                          color="dark"
                          outline
                          className="btn-sm waves-effect waves-light d-block"
                          onClick={() => handleGeneratePassword(setFieldValue)}>
                          <i className="bx bx-key align-middle mr-2"></i>
                          Generate
                        </Button>
                      </FormGroup>
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                {editingUser.role !== roles.SUPER_ADMIN && (
                  <Col md="4">
                    <FormGroup>
                      <Label htmlFor="countryIds">Country</Label>
                      <Select
                        name="countryIds"
                        id="countryIds"
                        isMulti
                        isSearchable
                        options={countries.map((country) => ({
                          label: country.name,
                          value: country.id,
                        }))}
                      />
                    </FormGroup>
                    <FormErrorMessage name="countryIds" />
                  </Col>
                )}
                {(editingUser.role !== user.role ||
                  user.role === roles.SUPER_ADMIN) && (
                  <>
                    <Col md="2">
                      <FormGroup>
                        <Label htmlFor="role">Role</Label>
                        <Select
                          name="role"
                          id="role"
                          isDisabled={
                            editingUser.role &&
                            editingUser.role !== roles.OPERATOR
                          }
                          options={allowedRoles.map((role) => ({
                            label: role,
                            value: role,
                          }))}
                        />
                      </FormGroup>
                      <FormErrorMessage name="role" />
                    </Col>
                    <Col md="3">
                      <Collapse isOpen={values.role === roles.NETWORKER}>
                        <FormGroup>
                          <Label htmlFor="networkId">Networker</Label>
                          <Select
                            name="networkId"
                            id="networkId"
                            isDisabled={editingUser.networkId}
                            options={networks.map(({ id, name }) => ({
                              label: name,
                              value: id,
                            }))}
                          />
                        </FormGroup>
                        <FormErrorMessage name="networkId" />
                      </Collapse>
                      <Collapse isOpen={values.role === roles.PUBLISHER}>
                        <FormGroup>
                          <Label htmlFor="publisherId">Publisher</Label>
                          <Select
                            name="publisherId"
                            id="publisherId"
                            isDisabled={editingUser.publisherId}
                            options={publishers.map(({ id, name }) => ({
                              label: name,
                              value: id,
                            }))}
                          />
                        </FormGroup>
                        <FormErrorMessage name="publisherId" />
                      </Collapse>
                      <Collapse isOpen={values.role === roles.CUSTOMER}>
                        <FormGroup>
                          <Label htmlFor="customerId">Customer</Label>
                          <Select
                            name="customerId"
                            id="customerId"
                            isDisabled={editingUser.customerId}
                            options={customers.map(({ id, name }) => ({
                              label: name,
                              value: id,
                            }))}
                          />
                        </FormGroup>
                        <FormErrorMessage name="customerId" />
                      </Collapse>
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col md="2">
                  <Collapse
                    isOpen={[
                      roles.MANAGER,
                      roles.NETWORKER,
                      roles.PUBLISHER,
                    ].includes(values.role)}>
                    <FormGroup>
                      <div
                        className="custom-control custom-switch mb-2"
                        dir="ltr">
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          id="showRevenues"
                          name="showRevenues"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="showRevenues">
                          Show Revenues?
                        </label>
                      </div>
                    </FormGroup>
                  </Collapse>
                </Col>
                <Col md="2">
                  <Collapse isOpen={values.showRevenues}>
                    <FormGroup>
                      <div
                        className="custom-control custom-switch mb-2"
                        dir="ltr">
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          id="showSiteRevenues"
                          name="showSiteRevenues"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="showSiteRevenues">
                          Show New Site Revenues?
                        </label>
                      </div>
                    </FormGroup>
                  </Collapse>
                </Col>
                <Col md="2">
                  <Collapse
                    isOpen={
                      !!(
                        values.customerId ||
                        publisherCustomerIds.includes(values.publisherId)
                      )
                    }>
                    <FormGroup>
                      <div
                        className="custom-control custom-switch mb-2"
                        dir="ltr">
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          id="isAdvertiser"
                          name="isAdvertiser"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="isAdvertiser">
                          Is Advertiser?
                        </label>
                      </div>
                    </FormGroup>
                  </Collapse>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                  label={
                    props.create ? 'Create and Send credential email' : 'Save'
                  }
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormUser;
