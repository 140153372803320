import React from 'react';
import { ViewButton } from '../../Common/DataTable/tableActionButtons';
import {
  getFilter,
  getLabel,
  roundTo,
} from '../../Common/DataTable/tableHelpers';
import moment from 'moment';

const createTableColumns = (sites, openViewModal, level, isStaff = false) => [
  {
    title: 'Site',
    name: 'siteId',
    width: 180,
    getCellValue: (row) => getLabel(row.siteId, sites),
    fixed: 'left',
  },
  {
    title: 'Day',
    name: 'day',
    width: 120,
    formatterComponent: ({ row }) =>
      moment(row.date).format('dddd, DD/MM/YYYY'),
    filter: (value, filter, row) =>
      getFilter(moment(row.date).format('dddd, DD/MM/YYYY'), filter.value),
    fixed: 'left',
    skip: level === 'monthly',
  },
  {
    title: 'Month',
    name: 'month',
    width: 120,
    formatterComponent: ({ row }) => moment(row.date).format('MMMM YYYY'),
    filter: (value, filter, row) =>
      getFilter(moment(row.date).format('MMMM YYYY'), filter.value),
    fixed: 'left',
    skip: level === 'daily',
  },
  {
    title: 'Available Impressions',
    name: 'availableImpressions',
    summary: 'sum',
    hidden: true,
    skip: !isStaff,
  },
  {
    title: 'Impressions',
    name: 'impressions',
    getCellValue: (row) =>
      isFinite(row.impressions) ? Math.floor(row.impressions) : 0,
    summary: 'sum',
  },
  {
    title: 'PPL',
    name: 'ppl',
    getCellValue: (row) => (isFinite(row.ppl) ? roundTo(row.ppl) : 0),
    formatterComponent: ({ value }) => `€ ${value}`,
    summary: 'avg',
    hidden: true,
    skip: !isStaff,
  },
  {
    title: 'Gross Revenue',
    name: 'grossRevenue',
    getCellValue: (row) =>
      isFinite(row.grossRevenue) ? roundTo(row.grossRevenue) : 0,
    formatterComponent: ({ value }) => `€ ${value}`,
    summary: 'sum-round-2',
    skip: !isStaff,
  },
  {
    title: 'Revenue Share',
    name: 'revenueShare',
    formatterComponent: ({ value }) => `${value}%`,
    hidden: true,
    skip: !isStaff,
  },
  {
    title: 'Net Revenue',
    name: 'netRevenue',
    getCellValue: (row) =>
      isFinite(row.netRevenue) ? roundTo(row.netRevenue) : 0,
    formatterComponent: ({ value }) => `€ ${value}`,
    summary: 'sum-round-2',
  },
  {
    title: 'Seeding Plays',
    name: 'seedingPlays',
    summary: 'sum',
  },
  {
    title: 'Seeding Net Revenue',
    name: 'seedingNetRevenue',
    getCellValue: (row) =>
      isFinite(row.seedingNetRevenue) ? roundTo(row.seedingNetRevenue) : 0,
    formatterComponent: ({ value }) => `€ ${value}`,
    summary: 'sum-round-2',
  },
  {
    title: 'Mixed Impressions',
    name: 'mixedImpressions',
    getCellValue: (row) =>
      isFinite(row.mixedImpressions) ? row.mixedImpressions : 0,
    summary: 'sum',
  },
  {
    title: 'Mixed Net Revenue',
    name: 'mixedNetRevenue',
    getCellValue: (row) =>
      isFinite(row.mixedNetRevenue) ? roundTo(row.mixedNetRevenue) : 0,
    formatterComponent: ({ value }) => `€ ${value}`,
    summary: 'sum-round-2',
  },
  {
    title: 'Total Net Revenue',
    name: 'totalNetRevenue',
    getCellValue: (row) =>
      isFinite(row.totalNetRevenue) ? roundTo(row.totalNetRevenue) : 0,
    formatterComponent: ({ value }) => `€ ${value}`,
    summary: 'sum-round-2',
  },
  {
    title: 'Action',
    name: 'action',
    width: 80,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
    skip: !isStaff,
  },
];

export default createTableColumns;
