import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  Collapse,
} from 'reactstrap';
import * as podcastsActions from '../../store/podcasts/actions';
import { useDispatch } from 'react-redux';

import { Form, Input } from '../../lib/availity/form';
import { Select } from '../../lib/availity/select';
import FormErrorMessage from '../Common/FormErrorMessage';
import '../../lib/availity/yup';
import formValidation from './formValidation';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import firebase from 'firebase/app';

import { TTS_VOICES, extractNodesAtDepth } from '../../config/synthesizer';

const SSML_SEPARATOR = '##_END_SSML_##';

const FormPodcast = ({ loading, podcast, create, closeModal }) => {
  const editingPodcast = podcast || {};
  const ssml = Array.isArray(editingPodcast.ssml)
    ? editingPodcast.ssml.join(SSML_SEPARATOR)
    : '';

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const ssmlArray =
      !values.ssml || values.ssml === ''
        ? firebase.firestore.FieldValue.delete()
        : values.ssml.split(SSML_SEPARATOR);

    dispatch(
      create
        ? podcastsActions.createPodcast(values.url, values.isPremium)
        : podcastsActions.updatePodcast({
            id: editingPodcast.id,
            ...values,
            ssml: ssmlArray,
          }),
    );
    closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{
            ...editingPodcast,
            ssml,
          }}
          validationSchema={formValidation(create)}
          onSubmit={handleSubmit}>
          {({ isValid, values }) => (
            <>
              {create ? (
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label htmlFor="url">URL</Label>
                      <Input id="url" name="url" />
                      <FormErrorMessage name="url" />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <div
                        className="custom-control custom-switch mb-2"
                        dir="ltr">
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          id="isPremium"
                          name="isPremium"
                          checked={!!values.isPremium}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="isPremium">
                          Premium Article?
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              ) : (
                <>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="title">Title</Label>
                        <Input id="title" name="title" />
                        <FormErrorMessage name="title" />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <Label htmlFor="author">Author</Label>
                        <Input id="author" name="author" />
                        <FormErrorMessage name="author" />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <Label htmlFor="scraped">Scraped</Label>
                        <Select
                          name="scraped"
                          id="scraped"
                          options={[
                            { value: 'new', label: 'New' },
                            { value: 'completed', label: 'Completed' },
                            { value: 'error', label: 'Error' },
                          ]}
                        />
                        <FormErrorMessage name="scraped" />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <Collapse
                        isOpen={
                          !!(
                            editingPodcast.scraped === 'completed' &&
                            values.scraped === 'completed'
                          )
                        }>
                        <FormGroup>
                          <Label htmlFor="synthesized">Synthesized</Label>
                          <Select
                            name="synthesized"
                            id="synthesized"
                            options={[
                              { value: 'new', label: 'New' },
                              { value: 'atFirstPlay', label: 'At First Play' },
                              { value: 'ready', label: 'Ready' },
                              { value: 'completed', label: 'Completed' },
                              { value: 'error', label: 'Error' },
                            ]}
                          />
                          <FormErrorMessage name="synthesized" />
                        </FormGroup>
                      </Collapse>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label htmlFor="text">Article</Label>
                        <Input id="text" name="text" type="textarea" />
                        <FormErrorMessage name="text" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label htmlFor="ssml">SSML</Label>
                        <Input id="ssml" name="ssml" type="textarea" />
                        <FormErrorMessage name="ssml" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <Label htmlFor="articleSelector">
                          Article Selector
                        </Label>
                        <Input id="articleSelector" name="articleSelector" />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <div
                          className="custom-control custom-switch mb-2"
                          dir="ltr">
                          <Input
                            type="checkbox"
                            className="custom-control-input"
                            id="skipTitle"
                            name="skipTitle"
                            checked={!!values.skipTitle}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="skipTitle">
                            Skip Article Title?
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <Label htmlFor="synthesizerEngine">Synthesizer</Label>
                        <Select
                          name="synthesizerEngine"
                          id="synthesizerEngine"
                          options={extractNodesAtDepth(TTS_VOICES, 0)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <Collapse isOpen={!!values.synthesizerEngine}>
                        <FormGroup>
                          <Label htmlFor="voiceType">Voice Type</Label>
                          <Select
                            name="voiceType"
                            id="voiceType"
                            options={extractNodesAtDepth(TTS_VOICES, 1, [
                              values.synthesizerEngine,
                            ])}
                          />
                        </FormGroup>
                      </Collapse>
                    </Col>
                    <Col md="2">
                      <Collapse isOpen={!!values.voiceType}>
                        <FormGroup>
                          <Label htmlFor="voiceName">Voice Name</Label>
                          <Select
                            name="voiceName"
                            id="voiceName"
                            options={extractNodesAtDepth(TTS_VOICES, 2, [
                              values.synthesizerEngine,
                              values.voiceType,
                            ])}
                          />
                        </FormGroup>
                      </Collapse>
                    </Col>
                  </Row>
                  <Row className="mt-3 mb-2">
                    <Col xs="auto" className="d-flex align-items-center">
                      <h5 className="m-0">Audio Player Behavior</h5>
                    </Col>
                    <Col xs="auto" className="d-flex align-items-center">
                      <p className="font-size-12 text-muted m-0">
                        (these settings override the site's default settings)
                      </p>
                    </Col>
                    <Col>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <FormGroup>
                        <div
                          className="custom-control custom-switch mb-2"
                          dir="ltr">
                          <Input
                            type="checkbox"
                            className="custom-control-input"
                            id="showPodcastImage"
                            name="showPodcastImage"
                            checked={!!values.showPodcastImage}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="showPodcastImage">
                            Show Podcast Image?
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <div
                          className="custom-control custom-switch mb-2"
                          dir="ltr">
                          <Input
                            type="checkbox"
                            className="custom-control-input"
                            id="renderPlayerWithoutPodcast"
                            name="renderPlayerWithoutPodcast"
                            checked={!!values.renderPlayerWithoutPodcast}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="renderPlayerWithoutPodcast">
                            Render Player Without Podcast?
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <div
                          className="custom-control custom-switch mb-2"
                          dir="ltr">
                          <Input
                            type="checkbox"
                            className="custom-control-input"
                            id="renderPlaylist"
                            name="renderPlaylist"
                            checked={!!values.renderPlaylist}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="renderPlaylist">
                            Render Playlist?
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <div
                          className="custom-control custom-switch mb-2"
                          dir="ltr">
                          <Input
                            type="checkbox"
                            className="custom-control-input"
                            id="forceHideStickyPlayer"
                            name="forceHideStickyPlayer"
                            checked={!!values.forceHideStickyPlayer}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="forceHideStickyPlayer">
                            Force Hide Sticky Player
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <div
                          className="custom-control custom-switch mb-2"
                          dir="ltr">
                          <Input
                            type="checkbox"
                            className="custom-control-input"
                            id="hideStickyPlayerOnMobile"
                            name="hideStickyPlayerOnMobile"
                            checked={!!values.hideStickyPlayerOnMobile}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="hideStickyPlayerOnMobile">
                            Hide Sticky On Mobile
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <div
                          className="custom-control custom-switch mb-2"
                          dir="ltr">
                          <Input
                            type="checkbox"
                            className="custom-control-input"
                            id="hideStickyPlayerOnDesktop"
                            name="hideStickyPlayerOnDesktop"
                            checked={!!values.hideStickyPlayerOnDesktop}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="hideStickyPlayerOnDesktop">
                            Hide Sticky On Desktop
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <FormGroup>
                        <Label htmlFor="heightFixedFloatingPlayer">
                          Height Fixed Player
                        </Label>
                        <InputGroup>
                          <Input
                            id="heightFixedFloatingPlayer"
                            name="heightFixedFloatingPlayer"
                            type="number"
                            step="1"
                          />
                          <InputGroupAddon addonType="append">
                            px
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
              <div className="modal-footer">
                <SubmitButton disabled={!isValid || loading} create={create} />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormPodcast;
