import moment from 'moment';

const yesterdayMoment = () => moment.utc().subtract(1, 'days');
const todayMoment = () => moment.utc();

const getMomentDateFromString = (dateString) => {
  switch (dateString.toLowerCase()) {
    case 'today':
      return moment.utc();
    case 'yesterday':
      return moment.utc().subtract(1, 'days');
    case 'end-of-year':
      return moment.utc().endOf('year');
    default:
      return moment.utc(dateString, 'DD/MM/YYYY');
  }
};

const datePresets = [
  {
    name: 'today',
    label: 'Today',
    getStartDate: todayMoment,
    getEndDate: todayMoment,
  },
  {
    name: 'yesterday',
    label: 'Yesterday',
    getStartDate: yesterdayMoment,
    getEndDate: yesterdayMoment,
  },
  {
    name: 'last7days',
    label: 'Last 7 days',
    getStartDate: () => moment.utc().subtract(6, 'days'),
    getEndDate: todayMoment,
  },
  {
    name: 'thisMonth',
    label: 'This month',
    getStartDate: () => moment.utc().startOf('month'),
    getEndDate: todayMoment,
  },
  {
    name: 'last30days',
    label: 'Last 30 days',
    getStartDate: () => moment.utc().subtract(29, 'days'),
    getEndDate: todayMoment,
  },
  {
    name: 'lastMonth',
    label: 'Last month',
    getStartDate: () => moment.utc().subtract(1, 'months').startOf('month'),
    getEndDate: () => moment.utc().subtract(1, 'months').endOf('month'),
  },
];

export { todayMoment, yesterdayMoment, getMomentDateFromString, datePresets };
