import React, { useState, memo } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  isAdmin as isAdminSelector,
  isManager as isManagerSelector,
} from '../../selectors/auth';
import { actualPublisher } from '../../selectors/publisher';
import colors from '../CommonStyle/colors';
import { isRtl } from '../../lib/languageTag';

const PreviewPodcast = ({ podcast }) => {
  const [showWebArticle, setShowWebArticle] = useState(false);

  const isAdmin = useSelector(isAdminSelector);
  const isManager = useSelector(isManagerSelector);
  const isStaff = isAdmin || isManager;

  const publisher = useSelector(actualPublisher);
  const showDownloadButton =
    isStaff || (publisher && publisher.downloadPodcasts);

  const { title, url: webURL, audioUrl, synthesized, langTag, text } = podcast;
  const url = `${audioUrl}?timestamp=${new Date().getTime()}`;

  const dir = isRtl(langTag) ? 'rtl' : 'ltr';

  return (
    <Card>
      <CardBody>
        <div className="text-center m-3">
          <h2 className="m-2" style={{ color: colors.indigo }} dir={dir}>
            {title}
          </h2>
          {isStaff && (
            <Button
              size="sm"
              color="dark"
              outline={!showWebArticle}
              className="waves-effect waves-light"
              onClick={() => setShowWebArticle(!showWebArticle)}>
              <i
                className={`bx bx-${
                  showWebArticle ? 'hide' : 'show'
                } font-size-16 align-middle mr-2`}></i>
              {`${showWebArticle ? 'Hide' : 'Show'} on Web`}
            </Button>
          )}
        </div>
        {audioUrl && synthesized === 'completed' && (
          <Row className="d-flex justify-content-center align-items-center">
            <audio
              src={url}
              className="mr-3"
              controls
              controlsList={showDownloadButton ? 'downlaod' : 'nodownload'}
            />
            {showDownloadButton && (
              <a
                href={url}
                className="btn btn-light"
                target="_blank"
                rel="noopener noreferrer">
                <i className="mdi mdi-download font-size-16 align-middle me-2"></i>{' '}
                Download MP3
              </a>
            )}
          </Row>
        )}
        <Row className="mx-3 my-4">
          <Col md={showWebArticle ? '6' : '12'}>
            <div
              dangerouslySetInnerHTML={{ __html: text }}
              className="text-justify"
              dir={dir}
            />
          </Col>
          {showWebArticle && (
            <Col md="6">
              <iframe
                src={webURL}
                title={title}
                width="100%"
                height="100%"
                style={{ border: '1px solid #ddd', borderRadius: '5px' }}
              />
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default memo(PreviewPodcast);
