import {
  call,
  fork,
  put,
  take,
  takeEvery,
  all,
  takeLatest,
} from 'redux-saga/effects';
import firebase from 'firebase/app';
import rsf from '../../helpers/firebase';
import types from './actionTypes';
import * as authActions from './actions';

import * as usersActions from '../users/actions';
import * as dashboardActions from '../dashboard/actions';
import * as networksActions from '../networks/actions';
import * as networkInsightsActions from '../networks/insights/actions';
import * as publishersActions from '../publishers/actions';
import * as publisherInsightsActions from '../publishers/insights/actions';
import * as sitesActions from '../sites/actions';
import * as siteInsightsActions from '../sites/insights/actions';
import * as siteRevenuesActions from '../sites/revenues/actions';
import * as siteNetRevenuesActions from '../revenues/sites/actions';
import * as monthlySiteRevenuesActions from '../sites/monthlyRevenues/actions';
import * as podcastsActions from '../podcasts/actions';
import * as podcastAnalysesActions from '../podcasts/analyses/actions';
import * as playlistsActions from '../playlists/actions';
import * as customersActions from '../customers/actions';
import * as insightsActions from '../insights/actions';
import * as clustersActions from '../clusters/actions';
import * as campaignsActions from '../campaigns/actions';
import * as campaignInsightsActions from '../campaigns/insights/actions';
import * as creativesActions from '../campaigns/creatives/actions';
import * as creativeInsightsActions from '../campaigns/creatives/insights/actions';
import * as countriesActions from '../countries/actions';
import * as countryInsightsActions from '../countries/insights/actions';

import { toDateFirebase } from '../../helpers/sharedFunction';
import toastr from 'toastr';
import roles from '../../config/roles';

const authGoogleProvider = new firebase.auth.GoogleAuthProvider();
authGoogleProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
authGoogleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');

function* loginWithGoogleSaga({ history }) {
  try {
    const { user, additionalUserInfo } = yield call(
      rsf.auth.signInWithPopup,
      authGoogleProvider,
    );
    additionalUserInfo.isNewUser
      ? yield call(createAdminSaga, user, 'google', history)
      : yield call(fetchAdminSaga, user.uid, history);
  } catch (error) {
    yield put(authActions.loginWithGoogleFailure(error));
  }
}

function* loginWithEmailSaga({ email, password, history }) {
  try {
    const { user } = yield call(
      rsf.auth.signInWithEmailAndPassword,
      email,
      password,
    );
    yield call(fetchAdminSaga, user.uid, history);
  } catch (error) {
    yield put(authActions.loginWithEmailFailure(error));
  }
}

function* registerWithEmailSaga({
  email,
  password,
  firstName,
  lastName,
  history,
}) {
  try {
    const { user } = yield call(
      rsf.auth.createUserWithEmailAndPassword,
      email,
      password,
    );
    const displayName = `${firstName} ${lastName}`;
    yield call(
      createAdminSaga,
      { ...user, firstName, lastName, displayName },
      'email',
      history,
    );
  } catch (error) {
    yield put(authActions.registerWithEmailFailure(error));
  }
}

function* logoutSaga({ history }) {
  try {
    yield put(usersActions.resetState());
    yield put(dashboardActions.resetState());
    yield put(networksActions.resetState());
    yield put(networkInsightsActions.resetState());
    yield put(publishersActions.resetState());
    yield put(publisherInsightsActions.resetState());
    yield put(sitesActions.resetState());
    yield put(siteInsightsActions.resetState());
    yield put(siteRevenuesActions.resetState());
    yield put(siteNetRevenuesActions.resetState());
    yield put(monthlySiteRevenuesActions.resetState());
    yield put(podcastsActions.resetState());
    yield put(podcastAnalysesActions.resetState());
    yield put(playlistsActions.resetState());
    yield put(customersActions.resetState());
    yield put(insightsActions.resetState());
    yield put(clustersActions.resetState());
    yield put(campaignsActions.resetState());
    yield put(campaignInsightsActions.resetState());
    yield put(creativesActions.resetState());
    yield put(creativeInsightsActions.resetState());
    yield put(countriesActions.resetState());
    yield put(countryInsightsActions.resetState());

    yield call(rsf.auth.signOut);
    yield put(authActions.logoutSuccess());
    history.push('/login');
  } catch (error) {
    yield put(authActions.logoutFailure(error));
  }
}

function* syncAdminSaga() {
  const channel = yield call(rsf.auth.channel);

  while (true) {
    const { error, user } = yield take(channel);

    if (user) {
      yield put(authActions.syncAdmin(user));
    } else {
      yield put(authActions.syncAdmin(null));
    }
  }
}

function* fetchAdminSaga(userId, history) {
  try {
    yield put(countriesActions.syncCountries());

    const adminSnap = yield call(rsf.firestore.getDocument, `users/${userId}`);

    const data = adminSnap.data();

    const admin = {
      id: userId,
      ...data,
      createdAt: toDateFirebase(adminSnap, data).toDate(),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(adminSnap, data, 'updatedAt').toDate(),
      }),
    };

    if (admin.role && admin.active) {
      yield put(authActions.fetchAdminSuccess(admin, ''));
      //If this user manage only one country then set countryId value
      if (admin.countryIds && admin.countryIds.length === 1)
        yield put(dashboardActions.setCountry(admin.countryIds[0]));
      history.push('/dashboard');
    } else {
      admin.provider === 'email' && history.push('/email-confirmation');
      admin.provider === 'google' && history.push('/account-confirmation');

      yield put(
        authActions.fetchAdminSuccess(
          null,
          'Contact AudioBoost staff to request access',
        ),
      );
    }
  } catch (error) {
    yield put(authActions.fetchAdminFailure(error));
  }
}

function* createAdminSaga(userData, provider, history) {
  try {
    const admin = {
      email: userData.email,
      displayName: userData.displayName,
      ...(userData.firstName && { firstName: userData.firstName }),
      ...(userData.lastName && { lastName: userData.lastName }),
      provider,
      active: false,
      role: userData.role || roles.OPERATOR,
      ...(userData.photoURL && { photoURL: userData.photoURL }),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    yield call(rsf.firestore.setDocument, `users/${userData.uid}`, admin);

    if (provider === 'email') {
      const actionAuthFunction = firebase
        .functions()
        .httpsCallable('actionAuth-actionAuth');

      const { data } = yield call(actionAuthFunction, {
        email: userData.email,
        action: 'email-verification',
      });
      if (data.error) throw new Error(data.error.message);

      history.push(`/email-verification?email=${userData.email}`);
    }

    if (provider === 'google') history.push('/account-confirmation');

    yield put(
      authActions.createAdminSuccess(
        'Contact AudioBoost staff to request access',
      ),
    );
  } catch (error) {
    yield put(authActions.createAdminFailure(error));
  }
}

function* passwordChangeSaga({ email, currentPassword, newPassword }) {
  try {
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      currentPassword,
    );
    yield call(rsf.auth.updatePassword, credential, newPassword);
    yield put(authActions.passwordChangeSuccess());
    toastr.success('Password updated!', '');
  } catch (error) {
    yield put(authActions.passwordChangeFailure(error));
    toastr.error(error.message, 'Error');
  }
}

function* passwordForgetSaga({ email }) {
  try {
    const actionAuthFunction = firebase
      .functions()
      .httpsCallable('actionAuth-actionAuth');

    const { data } = yield call(actionAuthFunction, {
      email,
      action: 'reset-password',
    });
    if (data.error) throw new Error(data.error.message);

    yield put(authActions.passwordForgetSuccess(data.message));
  } catch (error) {
    yield put(authActions.passwordForgetFailure(error));
  }
}

function* sendEmailVerificationSaga({ email }) {
  try {
    const actionAuthFunction = firebase
      .functions()
      .httpsCallable('actionAuth-actionAuth');

    const { data } = yield call(actionAuthFunction, {
      email,
      action: 'email-verification',
    });
    if (data.error) throw new Error(data.error.message);

    yield put(authActions.sendEmailVerificationSuccess(data.message));
  } catch (error) {
    yield put(authActions.sendEmailVerificationFailure(error));
  }
}

export default function* authSaga() {
  yield fork(syncAdminSaga);
  yield all([
    takeEvery(types.LOGIN_WITH_GOOGLE.REQUEST, loginWithGoogleSaga),
    takeEvery(types.LOGIN_WITH_EMAIL.REQUEST, loginWithEmailSaga),
    takeEvery(types.REGISTER_WITH_EMAIL.REQUEST, registerWithEmailSaga),
    takeEvery(types.FETCH_ADMIN.REQUEST, fetchAdminSaga),
    takeEvery(types.LOGOUT.REQUEST, logoutSaga),
    takeEvery(types.PASSWORD_CHANGE.REQUEST, passwordChangeSaga),
    takeEvery(types.PASSWORD_FORGET.REQUEST, passwordForgetSaga),
    takeLatest(
      types.SEND_EMAIL_VERIFICATION.REQUEST,
      sendEmailVerificationSaga,
    ),
  ]);
}
