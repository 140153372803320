import React from 'react';
import { ViewButton } from '../Common/DataTable/tableActionButtons';
import { getFilter, getLabel, roundTo } from '../Common/DataTable/tableHelpers';
import { CURRENCIES, AD_UNIT_TYPES } from '../../config/revenue';
import { capitalize, camelize } from '../../helpers/sharedFunction';

import moment from 'moment';

const createTableColumns = (sites, openViewModal, isStaff, t) => [
  {
    title: 'Site',
    name: 'siteId',
    width: 180,
    getCellValue: (row) => getLabel(row.siteId, sites),
    fixed: 'left',
  },
  {
    title: 'Day',
    name: 'day',
    width: 120,
    formatterComponent: ({ row }) =>
      moment(row.date).format('dddd, DD/MM/YYYY'),
    filter: (value, filter, row) =>
      getFilter(moment(row.date).format('dddd, DD/MM/YYYY'), filter.value),
    fixed: 'left',
  },
  {
    title: 'Impressions',
    name: 'impressions',
    getCellValue: (row) => (isFinite(row.impressions) ? row.impressions : 0),
    summary: 'sum',
  },
  ...Object.values(AD_UNIT_TYPES).map((type) => ({
    title: `${t(capitalize(type))} Revenue`,
    name: `net${camelize(type, false)}Revenue`,
    getCellValue: (row) =>
      isFinite(row[`net${camelize(type, false)}Revenue`])
        ? roundTo(row[`net${camelize(type, false)}Revenue`])
        : 0,
    formatterComponent: ({ value, row }) =>
      `${CURRENCIES[row?.currency || 'EUR']} ${value}`,
    summary: 'sum-round-2',
  })),
  {
    title: 'eCPM',
    name: 'cpm',
    getCellValue: (row) => (isFinite(row.cpm) ? roundTo(row.cpm) : 0),
    formatterComponent: ({ value, row }) =>
      `${CURRENCIES[row?.currency || 'EUR']} ${value}`,
  },
  {
    title: 'Total Net Revenue',
    name: 'totalNetRevenue',
    getCellValue: (row) =>
      isFinite(row.totalNetRevenue) ? roundTo(row.totalNetRevenue) : 0,
    formatterComponent: ({ value, row }) =>
      `${CURRENCIES[row?.currency || 'EUR']} ${value}`,
    summary: 'sum-round-2',
  },
  {
    title: 'Action',
    name: 'action',
    width: 80,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
    skip: !isStaff,
  },
];

export default createTableColumns;
