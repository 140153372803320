import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import * as publishersActions from '../../store/publishers/actions';
import * as sitesActions from '../../store/sites/actions';
import * as networksActions from '../../store/networks/actions';

import Modal from '../../components/Common/Modal/Modal';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import FormPublisher from '../../components/Publishers/FormPublisher';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Publishers/tableColumns';
import { CreateButton } from '../../components/Common/DataTable/tableActionButtons';

const List = (props) => {
  const loading = useSelector((state) => state.Publisher.loading);
  const publishers = useSelector((state) => state.Publisher.publishers);
  const sites = useSelector((state) => state.Site.sites);
  const networks = useSelector((state) => state.Network.networks);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedPublisher, setSelectedPublisher] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(publishersActions.syncPublishers());
    dispatch(sitesActions.syncSites());
    dispatch(networksActions.syncNetworks());
  }, []);

  const openViewModal = (publisher) => {
    setSelectedPublisher(publisher);
    setViewModal(true);
  };

  const openEditModal = (publisher) => {
    setSelectedPublisher(publisher);
    setEditModal(true);
  };

  const columns = useMemo(
    () => createTableColumns(sites, networks, openViewModal, openEditModal),
    [sites, networks],
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Publisher" breadcrumbItem="List" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new Publisher"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={publishers}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Publisher"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedPublisher}
              icon="bx bx-buildings"
              collection="publishers"
            />
          </Modal>
          <Modal
            size="xl"
            title="Publisher"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormPublisher
              closeModal={() => setEditModal(false)}
              create={!selectedPublisher}
              loading={loading}
              publisher={selectedPublisher}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
