import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import * as networksActions from '../../store/networks/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectPublishersForNetworkSelect } from '../../selectors/publisher';

import { Form, Input } from '../../lib/availity/form';
import { Select } from '../../lib/availity/select';
import FormErrorMessage from '../Common/FormErrorMessage';
import '../../lib/availity/yup';
import formValidation from './formValidation';
import { SubmitButton } from '../Common/Modal/modalActionButtons';

const FormNetwork = (props) => {
  const editingNetwork = props.network || {};
  const publishers = useSelector(
    selectPublishersForNetworkSelect(editingNetwork),
  );

  const [active, setActive] = useState(editingNetwork.active || false);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const network = {
      ...values,
      active,
    };

    dispatch(
      props.create
        ? networksActions.createNetwork({
            ...network,
          })
        : networksActions.updateNetwork(
            {
              id: editingNetwork.id,
              ...network,
            },
            editingNetwork.publisherIds,
          ),
    );
    props.closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{ ...editingNetwork, active }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid }) => (
            <>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input id="name" name="name" />
                    <FormErrorMessage name="name" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="active"
                        defaultChecked={active}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="active"
                        onClick={(e) => {
                          setActive(!active);
                        }}>
                        Active?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="publisherIds">Publisher</Label>
                    <Select
                      name="publisherIds"
                      id="publisherIds"
                      isMulti
                      options={publishers}
                    />
                    <FormErrorMessage name="publisherIds" />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="headerImageUrl">Header Image</Label>
                    <Input id="headerImageUrl" name="headerImageUrl" />
                    <FormErrorMessage name="headerImageUrl" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col xs="auto" className="d-flex align-items-center">
                  <h5 className="m-0">Sellers.json Configuration</h5>
                </Col>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="domain">Business Domain</Label>
                    <Input id="domain" name="domain" />
                    <FormErrorMessage name="domain" />
                    <p className="font-size-12 text-muted">
                      Network domain for sellers.json
                    </p>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="truvidSellerId">
                      Truvid Sellers.json ID
                    </Label>
                    <InputGroup>
                      <Input id="truvidSellerId" name="truvidSellerId" />
                      <InputGroupAddon addonType="append">
                        <button
                          className="btn btn-primary p-0"
                          style={{ width: '31px' }}
                          type="button"
                          onClick={() =>
                            window.open(
                              'https://www.truvid.com/sellers.json',
                              '_blank',
                            )
                          }>
                          <i className="bx bx-link-external d-block font-size-15" />
                        </button>
                      </InputGroupAddon>
                    </InputGroup>
                    <FormErrorMessage name="truvidSellerId" />
                    <p className="font-size-12 text-muted">
                      Enter the seller ID provided by Truvid
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormNetwork;
