import React, { useState, useCallback } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import * as publishersActions from '../../store/publishers/actions';
import { useDispatch } from 'react-redux';
import { Form, Input } from '../../lib/availity/form';
import { Select } from '../../lib/availity/select';
import FormErrorMessage from '../Common/FormErrorMessage';
import '../../lib/availity/yup';
import formValidation from './formValidation';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import { generateRandomString } from '../../helpers/sharedFunction';
import firebase from 'firebase/app';

const FormPublisher = (props) => {
  const editingPublisher = props.publisher || {};

  const [active, setActive] = useState(editingPublisher.active || false);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const apiKey = values.apiKey && values.apiKey !== '' ? values.apiKey : null;
    delete values.apiKey;

    const publisher = {
      ...values,
      active,
    };

    dispatch(
      props.create
        ? publishersActions.createPublisher({
            ...publisher,
            ...(apiKey && { apiKey }),
          })
        : publishersActions.updatePublisher({
            id: editingPublisher.id,
            ...publisher,
            apiKey: apiKey || firebase.firestore.FieldValue.delete(),
          }),
    );
    props.closeModal();
  };

  const handleGenerateAPIKey = useCallback((setFieldValue) => {
    const key = generateRandomString(30);
    setFieldValue('apiKey', key);
  }, []);

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{ ...editingPublisher, active }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values, setFieldValue }) => (
            <>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input id="name" name="name" />
                    <FormErrorMessage name="name" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="logoUrl">Logo</Label>
                    <Input id="logoUrl" name="logoUrl" />
                    <FormErrorMessage name="logoUrl" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="model">Model</Label>
                    <Select
                      name="model"
                      id="model"
                      options={[
                        { value: 'adv', label: 'Advertising' },
                        { value: 'saas', label: 'SaaS' },
                      ]}
                    />
                    <FormErrorMessage name="model" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="active"
                        defaultChecked={active}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="active"
                        onClick={(e) => {
                          setActive(!active);
                        }}>
                        Active?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="apiKey">API Key</Label>
                    <Input id="apiKey" name="apiKey" disabled />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Generate API Key</Label>
                    <Button
                      color="dark"
                      outline
                      className="btn-sm waves-effect waves-light d-block"
                      onClick={() => handleGenerateAPIKey(setFieldValue)}>
                      <i className="bx bx-key align-middle mr-2"></i>
                      Generate
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col xs="auto" className="d-flex align-items-center">
                  <h5 className="m-0">Publisher Permissions</h5>
                </Col>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="createPodcasts"
                        name="createPodcasts"
                        checked={!!values.createPodcasts}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="createPodcasts">
                        Create Podcasts?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="downloadPodcasts"
                        name="downloadPodcasts"
                        checked={!!values.downloadPodcasts}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="downloadPodcasts">
                        Download Podcasts?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col xs="auto" className="d-flex align-items-center">
                  <h5 className="m-0">Sellers.json Configuration</h5>
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                  <p className="font-size-12 text-muted m-0">
                    (optional if the publisher is associated with a network)
                  </p>
                </Col>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="domain">Business Domain</Label>
                    <Input id="domain" name="domain" />
                    <FormErrorMessage name="domain" />
                    <p className="font-size-12 text-muted">
                      Publisher domain for sellers.json
                    </p>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="truvidSellerId">
                      Truvid Sellers.json ID
                    </Label>
                    <InputGroup>
                      <Input id="truvidSellerId" name="truvidSellerId" />
                      <InputGroupAddon addonType="append">
                        <button
                          className="btn btn-primary p-0"
                          style={{ width: '31px' }}
                          type="button"
                          onClick={() =>
                            window.open(
                              'https://www.truvid.com/sellers.json',
                              '_blank',
                            )
                          }>
                          <i className="bx bx-link-external d-block font-size-15" />
                        </button>
                      </InputGroupAddon>
                    </InputGroup>
                    <FormErrorMessage name="truvidSellerId" />
                    <p className="font-size-12 text-muted">
                      Enter the seller ID provided by Truvid
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormPublisher;
