import '../../lib/availity/yup';
import * as yup from 'yup';

const DOMAIN_REGEXP =
  /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,})$/;

const formValidation = yup.object().shape({
  name: yup.string().required(),
  logoUrl: yup.string().url(),
  model: yup.string().required(),
  active: yup.boolean().required(),
  apiKey: yup.string(),
  createPodcasts: yup.boolean(),
  downloadPodcasts: yup.boolean(),
  domain: yup
    .string()
    .nullable()
    .matches(DOMAIN_REGEXP, 'invalid domain format'),
  truvidSellerId: yup.string(),
});

export default formValidation;
