import '../../lib/availity/yup';
import * as yup from 'yup';

import roles from '../../config/roles';

const formValidation = (isCreation, emailsInUse, publisherCustomerIds) =>
  yup.object().shape({
    firstName: yup.string().label('first name').required(),
    lastName: yup.string().label('last name').required(),
    displayName: yup.string().label('display name').required(),
    email: yup.string().email().notOneOf(emailsInUse),
    role: yup.string().required(),
    ...(isCreation && {
      password: yup.string().required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    }),
    countryIds: yup.string().when('role', {
      is: (value) => value !== roles.SUPER_ADMIN,
      then: yup.string().required(),
    }),
    networkId: yup.string().when('role', {
      is: (value) => value === roles.NETWORKER,
      then: yup.string().required(),
    }),
    publisherId: yup.string().when('role', {
      is: (value) => value === roles.PUBLISHER,
      then: yup.string().required(),
    }),
    customerId: yup.string().when('role', {
      is: (value) => value === roles.CUSTOMER,
      then: yup.string().required(),
    }),
    showRevenues: yup.boolean().when('role', {
      is: (value) => [roles.PUBLISHER, roles.MANAGER].includes(value),
      then: yup
        .boolean()
        .transform((value, originalValue) => (originalValue ? true : false)),
    }),
    showSiteRevenues: yup.boolean().when('showRevenues', {
      is: (value) => value,
      then: yup
        .boolean()
        .transform((value, originalValue) => (originalValue ? true : false)),
    }),
    isAdvertiser: yup.boolean().when(['role', 'publisherId'], {
      is: (role, publisherId) =>
        role === roles.CUSTOMER || publisherCustomerIds.includes(publisherId),
      then: yup
        .boolean()
        .transform((value, originalValue) => (originalValue ? true : false)),
    }),
  });

export default formValidation;
