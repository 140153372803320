export const REVENUE_LEVEL = {
  DAILY: 'daily',
  MONTHLY: 'monthly',
  AD_UNIT: 'adUnit',
  SITE: 'site',
};

export const MANDATORY_CSV_IMPORT_FIELDS = [
  {
    name: 'month',
    type: 'date-string',
    required: true,
  },
  {
    name: 'siteId',
    type: 'id',
    required: true,
  },
  {
    name: 'publisherId',
    type: 'id',
    required: true,
  },
  {
    name: 'networkId',
    type: 'id',
    required: false,
  },
  {
    name: 'countryId',
    type: 'id',
    required: true,
  },
  {
    name: 'siteName',
    type: 'string',
    required: true,
  },
  {
    name: 'publisherName',
    type: 'string',
    required: true,
  },
  {
    name: 'networkName',
    type: 'string',
    required: false,
  },
  {
    name: 'first-quartile',
    type: 'numeric',
    required: true,
  },
  {
    name: 'completed',
    type: 'numeric',
    required: true,
  },
  {
    name: 'averageSongDuration',
    type: 'numeric',
    required: true,
  },
  {
    name: 'factor',
    type: 'numeric',
    required: true,
  },
  {
    name: 'availableImpressions',
    type: 'numeric',
    required: true,
  },
  {
    name: 'fillRate',
    type: 'percentage',
    required: true,
  },
  {
    name: 'impressions',
    type: 'numeric',
    required: true,
  },
  {
    name: 'ppl',
    type: 'numeric',
    required: true,
  },
  {
    name: 'grossRevenue',
    type: 'numeric',
    required: true,
  },
  {
    name: 'revenueShare',
    type: 'percentage',
    required: true,
  },
  {
    name: 'netRevenue',
    type: 'numeric',
    required: true,
  },
  {
    name: 'seedingPlays',
    type: 'numeric',
    required: true,
  },
  {
    name: 'seedingPpl',
    type: 'numeric',
    required: true,
  },
  {
    name: 'seedingNetRevenue',
    type: 'numeric',
    required: true,
  },
  {
    name: 'mixedImpressions',
    type: 'numeric',
    required: true,
  },
  {
    name: 'mixedNetRevenue',
    type: 'numeric',
    required: true,
  },
  {
    name: 'totalNetRevenue',
    type: 'numeric',
    required: true,
  },
];

export const CURRENCIES = {
  EUR: '€',
  USD: '$',
  GBP: '£',
  JPY: '¥',
  AUD: 'A$',
  CAD: 'C$',
  CHF: 'CHF',
  CNY: '¥',
  SEK: 'kr',
  NZD: 'NZ$',
  MXN: '$',
  SGD: 'S$',
  HKD: 'HK$',
  NOK: 'kr',
  KRW: '₩',
  TRY: '₺',
  INR: '₹',
  RUB: '₽',
  BRL: 'R$',
  ZAR: 'R',
};

export const REVENUE_PROVIDERS = {
  AUDIOBOOST_GAM: 'audioboost-gam',
  ADSWIZZ: 'adswizz',
  SPREAKER: 'spreaker',
  TRITON: 'triton',
  AMAZON: 'amazon',
  XANDR: 'xandr',
  CRITEO: 'criteo',
};

export const REVENUE_PRODUCTS = {
  SPEAKUP_ARTICLE: 'speakup-article',
};

export const AD_UNIT_TYPES = {
  AUDIO_VIDEO: 'audio-video',
  SEEDING: 'seeding',
  MIXED: 'mixed',
};

export const MANDATORY_SPREAKER_CSV_IMPORT_FIELDS = [
  {
    name: 'day',
    type: 'date-string',
    required: true,
  },
  {
    name: 'podcast id',
    type: 'id',
    required: true,
  },
  {
    name: 'impressions',
    type: 'numeric',
    required: true,
  },
  {
    name: 'net revenue',
    type: 'numeric',
    required: false,
  },
];

export const DEFAULT_REVENUE_SHARE = {
  SEEDING: 25,
  AUDIO_VIDEO: 25,
  MIXED: 25,
};
