import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  Alert,
  Spinner,
} from 'reactstrap';
import { Form, Input } from '../../lib/availity/form';
import { FieldArray } from 'formik';
import FormErrorMessage from '../Common/FormErrorMessage';
import firebase from 'firebase/app';
import rsf from '../../helpers/firebase';

import '../../lib/availity/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const generateSiteConfigFunction = firebase
  .functions()
  .httpsCallable('generateSiteConfig-generateSiteConfig');

const formValidation = yup.object().shape({
  urls: yup.array().of(yup.string().label('site URL').url().required()).min(3),
});

const FindArticleConfig = ({ updateSelectors, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectors, setSelectors] = useState(null);

  const generateSiteConfig = ({ urls }) => {
    setLoading(true);
    setError(null);
    setSelectors(null);
    generateSiteConfigFunction({ urls })
      .then(({ data }) => {
        console.log(data);
        if (data.error) throw new Error(data.error.message);

        const { titleSelector, articleSelectors, excludeHTMLElements } = data;
        setSelectors({ titleSelector, articleSelectors, excludeHTMLElements });
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdateSelectors = (selectors) => {
    updateSelectors(selectors);
    closeModal();
  };

  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md="12">
            <p className="font-size-16 mb-4">
              {t(
                'Enter some URLs of articles you want to analyze to find the right configuration for this site (minimum 3 URLs)',
              )}
              .
            </p>
          </Col>
        </Row>
        {error && <Alert color="danger">{error}</Alert>}
        <Form
          initialValues={{
            urls: [],
          }}
          validationSchema={formValidation}>
          {({ isValid, values }) => (
            <>
              <Row>
                <Col md="7">
                  <FieldArray
                    name="urls"
                    render={(arrayHelpers) => {
                      const urls = values.urls;
                      return (
                        <FormGroup>
                          <Label htmlFor="urls">Site URLs</Label>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline btn-light ml-4"
                            disabled={loading}
                            onClick={() => arrayHelpers.push([])}>
                            <i className="bx bx-plus font-size-11 align-middle"></i>{' '}
                            Add
                          </button>
                          {Array.isArray(urls) &&
                            urls.map((url, index) => (
                              <Row key={index} className="mb-2">
                                <Col md="12">
                                  <InputGroup>
                                    <Input
                                      name={`urls.${index}`}
                                      type="textarea"
                                      disabled={loading}
                                    />
                                    <InputGroupAddon addonType="append">
                                      <button
                                        className="btn btn-danger p-0"
                                        style={{ width: '31px' }}
                                        type="button"
                                        disabled={loading}
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }>
                                        <i className="bx bx-trash d-block font-size-15" />
                                      </button>
                                    </InputGroupAddon>
                                  </InputGroup>
                                  <FormErrorMessage name={`urls.${index}`} />
                                </Col>
                              </Row>
                            ))}
                        </FormGroup>
                      );
                    }}
                  />
                  <button
                    type="button"
                    className="btn btn-sm btn-dark btn-block waves-effect mt-2"
                    onClick={() => generateSiteConfig(values)}
                    disabled={!isValid || loading}>
                    <i className="fas fa-hat-wizard font-size-16 align-middle mr-2"></i>
                    {t('Find Configuration')}
                  </button>
                </Col>
                <Col md="5" className="pl-4">
                  {loading && (
                    <div className="text-center">
                      <Spinner
                        className="mt-5"
                        color="warning"
                        size={14}
                        speed={1}
                        animating={true}
                      />
                      <p className="text-muted font-weight-medium mt-3">
                        {t(
                          'May take a bit of time to complete the process, please wait...',
                        )}
                      </p>
                    </div>
                  )}
                  {selectors && (
                    <>
                      <div className="d-flex align-items-center mt-1">
                        <div className="mr-3 avatar-xs align-self-center rounded-circle overflow-hidden">
                          <span className="avatar-title bg-success">
                            <i className="bx bx-check-circle font-size-20"></i>
                          </span>
                        </div>
                        <h5 className="my-0">{t('Selectors found!')}</h5>
                      </div>
                      <div className="my-3">
                        <p>
                          <strong>{t('Title Selector')}:</strong>{' '}
                          {selectors.titleSelector}
                        </p>
                        <p>
                          <strong>{t('Article Selectors')}:</strong>{' '}
                          {selectors.articleSelectors.join(', ')}
                        </p>
                        <p>
                          <strong>{t('Exclude HTML Elements')}:</strong>{' '}
                          {selectors.excludeHTMLElements.join(', ')}
                        </p>
                      </div>
                      <button
                        type="button"
                        className="btn btn-sm btn-success waves-effect"
                        onClick={() => handleUpdateSelectors(selectors)}
                        disabled={!isValid || loading}>
                        <i className="mdi mdi-web font-size-16 align-middle mr-2"></i>
                        {t('Update Site Configuration & Close')}
                      </button>
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FindArticleConfig;
