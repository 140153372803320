import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
import Auth from './auth/reducer';
import Dashboard from './dashboard/reducer';
import Network from './networks/reducer';
import NetworkInsight from './networks/insights/reducer';
import Publisher from './publishers/reducer';
import PublisherInsight from './publishers/insights/reducer';
import Site from './sites/reducer';
import SiteInsight from './sites/insights/reducer';
import SiteRevenue from './sites/revenues/reducer';
import MonthlySiteRevenue from './sites/monthlyRevenues/reducer';
import Revenue from './revenues/adUnits/reducer';
import SiteNetRevenue from './revenues/sites/reducer';
import Podcast from './podcasts/reducer';
import PodcastAnalysis from './podcasts/analyses/reducer';
import Playlist from './playlists/reducer';
import Customer from './customers/reducer';
import Insight from './insights/reducer';
import Cluster from './clusters/reducer';
import Campaign from './campaigns/reducer';
import CampaignInsight from './campaigns/insights/reducer';
import Creative from './campaigns/creatives/reducer';
import CreativeInsight from './campaigns/creatives/insights/reducer';
import Transaction from './transactions/reducer';
import User from './users/reducer';
import Country from './countries/reducer';
import CountryInsight from './countries/insights/reducer';

const rootReducer = combineReducers({
  // public
  Layout,
  Auth,
  Dashboard,
  Network,
  NetworkInsight,
  Publisher,
  PublisherInsight,
  Site,
  SiteInsight,
  SiteRevenue,
  MonthlySiteRevenue,
  Revenue,
  SiteNetRevenue,
  Podcast,
  PodcastAnalysis,
  Playlist,
  Customer,
  Insight,
  Cluster,
  Campaign,
  CampaignInsight,
  Creative,
  CreativeInsight,
  Transaction,
  User,
  Country,
  CountryInsight,
});

export default rootReducer;
