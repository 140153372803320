import React from 'react';
import {
  ViewButton,
  EditButton,
  ActivateUserButton,
  DisableUserButton,
} from '../Common/DataTable/tableActionButtons';
import { getLabel, getFilter } from '../Common/DataTable/tableHelpers';
import roles from '../../config/roles';
import moment from 'moment';

const createTableColumns = (
  openViewModal,
  openEditModal,
  openUserActivationAlert,
  openUserDisablingAlert,
  user,
  networks,
  publishers,
  customers,
) => [
  {
    title: 'Display Name',
    name: 'displayName',
  },
  {
    title: 'Email',
    name: 'email',
  },
  {
    title: 'Role',
    name: 'role',
    width: 120,
  },
  {
    title: 'Network',
    name: 'networkId',
    width: 180,
    formatterComponent: ({ value }) => getLabel(value, networks, 'id', 'name'),
    filter: (value, filter) =>
      getFilter(getLabel(value, networks, 'id', 'name'), filter.value),
  },
  {
    title: 'Publisher',
    name: 'publisherId',
    width: 180,
    formatterComponent: ({ value }) =>
      getLabel(value, publishers, 'id', 'name'),
    filter: (value, filter) =>
      getFilter(getLabel(value, publishers, 'id', 'name'), filter.value),
  },
  {
    title: 'Customer',
    name: 'customerId',
    width: 180,
    formatterComponent: ({ value }) => getLabel(value, customers, 'id', 'name'),
    filter: (value, filter) =>
      getFilter(getLabel(value, customers, 'id', 'name'), filter.value),
  },
  {
    title: 'Advertiser',
    name: 'isAdvertiser',
    width: 80,
    sort: true,
    formatterComponent: ({ value }) =>
      value ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-danger font-size-12">NO</span>
      ),
    filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
  },
  {
    title: 'Active',
    name: 'active',
    width: 80,
    sort: true,
    formatterComponent: ({ value }) =>
      value ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-danger font-size-12">NO</span>
      ),
    filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
  },
  {
    title: 'Created At',
    name: 'createdAt',
    width: 120,
    formatterComponent: ({ value }) => `${moment(value).fromNow()}`,
  },
  {
    title: 'Action',
    name: 'action',
    width: 140,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        {(user.role !== row.role || user.id === row.id) && (
          <EditButton row={row} onPress={openEditModal} />
        )}
        {openUserActivationAlert &&
          !row.active &&
          Array.isArray(row.countryIds) &&
          row.countryIds.length &&
          row.role !== roles.OPERATOR && (
            <ActivateUserButton row={row} onPress={openUserActivationAlert} />
          )}
        {openUserDisablingAlert && row.active && (
          <DisableUserButton row={row} onPress={openUserDisablingAlert} />
        )}
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
