import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Collapse,
  Alert,
  FormGroup,
  Spinner,
} from 'reactstrap';
import CSVUploadReader from '../../Common/CSVReader';

import * as monthlyRevenuesActions from '../../../store/sites/monthlyRevenues/actions';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Dots } from 'react-activity';
import moment from 'moment';
import axios from 'axios';

const START_DATE = '2024-11-01';
const CONFIG_CSV = {
  header: true,
  delimiter: ';',
  dynamicTyping: true,
  skipEmptyLines: true,
  transform: (value, name) =>
    /^\d+,\d+$/.test(value) ? value.replace(',', '.') : value,
};

const DOWNLOAD_STATUS = {
  INITIAL: '',
  DOWNLOADING: 'downloading',
  COMPLETED: 'completed',
  ERROR: 'error',
};

const getMonths = (startDate) => {
  const starDate = moment(startDate);
  const endDate = moment();

  let date = starDate;
  let dates = [];

  while (date <= endDate) {
    const firstDay = date.startOf('month');
    dates.push({
      value: firstDay.format('YYYY-MM-DD'),
      label: firstDay.format('MMMM YYYY'),
    });
    date = firstDay.add(1, 'months');
  }

  return dates;
};

const ImportRevenues = () => {
  const [month, setMonth] = useState(null);
  const [isFinalBalance, setIsFinalBalance] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState(DOWNLOAD_STATUS.INITIAL);
  const [downloadError, setDownloadError] = useState('');

  const loading = useSelector((state) => state.MonthlySiteRevenue.loading);
  const error = useSelector((state) => state.MonthlySiteRevenue.error);
  const success = useSelector((state) => state.MonthlySiteRevenue.success);
  const userId = useSelector((state) => state.Auth.admin.id);

  const dispatch = useDispatch();

  const handleCSVData = ({ data, errors }) => {
    resetError();
    if (errors.length) {
      setDownloadStatus(DOWNLOAD_STATUS.ERROR);
      setDownloadError(`${errors[0].message} at row ${errors[0].row}`);
    }
    dispatch(
      monthlyRevenuesActions.createMonthlySiteRevenue(
        data,
        month,
        isFinalBalance,
      ),
    );
  };

  const resetError = () => {
    setDownloadError('');
    dispatch(monthlyRevenuesActions.resetState());
  };

  const handleUploadRejected = () => {
    setDownloadStatus(DOWNLOAD_STATUS.ERROR);
    setDownloadError('Upload error');
  };

  const handleMonth = (month) => {
    setMonth(month === 'month' ? null : month);
  };

  const handleDownloadReport = () => {
    resetError();
    setDownloadStatus(DOWNLOAD_STATUS.DOWNLOADING);
    axios
      .post(
        `https://us-central1-${process.env.REACT_APP_PROJECTID}.cloudfunctions.net/ws-ws/download-insights/${month}/`,
        { userId },
        { responseType: 'blob' },
      )
      .then((response) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Audioboost_Revenue_${month}.xlsx`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setDownloadStatus(DOWNLOAD_STATUS.COMPLETED);
      })
      .catch(async (error) => {
        const { message } = JSON.parse(await error.response.data.text());
        setDownloadStatus(DOWNLOAD_STATUS.ERROR);
        setDownloadError(message);
        console.log(message);
      })
      .finally(() => {
        // always executed
      });
  };

  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        {loading ? (
          <Dots />
        ) : (
          <>
            {(downloadStatus === DOWNLOAD_STATUS.ERROR || error) && (
              <Alert color="danger">{downloadError || error}</Alert>
            )}
            {success && <Alert color="success">{success}</Alert>}
            <Row>
              <Col md="12">
                <p className="font-size-16 mb-4">
                  {t(
                    'Select the month for which you want to download the report or upload the revenues',
                  )}
                  .
                </p>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="input-group input-group">
                  <select
                    className="custom-select custom-select"
                    defaultValue="month"
                    onChange={(e) => handleMonth(e.target.value)}>
                    <option key="month" value="month">
                      {t('Month')}
                    </option>
                    {getMonths(START_DATE).map(({ label, value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                  <div className="input-group-append">
                    <label className="input-group-text">{t('Month')}</label>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <Collapse isOpen={!!month}>
                  <button
                    type="button"
                    disabled={downloadStatus === DOWNLOAD_STATUS.DOWNLOADING}
                    className="btn btn-dark waves-effect"
                    onClick={handleDownloadReport}>
                    <i className="bx bx-cloud-download font-size-16 align-middle mr-2"></i>
                    Download Report
                  </button>
                </Collapse>
              </Col>
              {downloadStatus === DOWNLOAD_STATUS.DOWNLOADING && (
                <Col md="2">
                  <Spinner animation="border" variant="dark" />
                </Col>
              )}
            </Row>
            <Collapse isOpen={!!month}>
              <Row className="mt-4">
                <Col md="12">
                  <Col md="6">
                    <FormGroup>
                      <div
                        className="custom-control custom-switch mb-2"
                        dir="ltr">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="isFinalBalance"
                          defaultChecked={isFinalBalance}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="isFinalBalance"
                          onClick={(e) => {
                            setIsFinalBalance(!isFinalBalance);
                          }}>
                          Final Balance?
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <CSVUploadReader
                    config={CONFIG_CSV}
                    onUploadAccepted={handleCSVData}
                    onUploadRejected={handleUploadRejected}
                  />
                </Col>
              </Row>
            </Collapse>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default ImportRevenues;
