import React, { useState, memo } from 'react';
import { Card, CardBody, CardTitle, ButtonGroup, Button } from 'reactstrap';

import { useSelector } from 'react-redux';
import { drawSeriesSiteRevenueGraph } from '../../selectors/revenue';

import ReactApexChart from 'react-apexcharts';
import CenterBox from '../Common/CenterBox';
import { useTranslation } from 'react-i18next';
import { camelize } from '../../helpers/sharedFunction';
import { AD_UNIT_TYPES } from '../../config/revenue';

const SiteRevenueGraph = ({ dateRange, loading }) => {
  const { t } = useTranslation();

  const [selectAdUnitType, setSelectAdUnitType] = useState('all');

  const dataGraph = useSelector(
    drawSeriesSiteRevenueGraph(dateRange, selectAdUnitType, t),
  );

  const dailyGraph = {
    series: dataGraph.series,
    options: {
      chart: {
        toolbar: 'false',
        type: 'line',
        stacked: false,
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: 'dddd, dd MMM yyyy',
        },
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 10,
          offsetX: 20,
        },
      },
      dataLabels: {
        enabled: !1,
      },
      stroke: {
        curve: 'smooth',
        width: [0, 2],
        dashArray: [0, 6],
      },
      yaxis: [
        {
          seriesName: dataGraph.series[0].name,
          tickAmount: 5,
          max: parseInt(dataGraph.max[0]),
          min: parseInt(dataGraph.min[0]),
          forceNiceScale: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: dataGraph.series[0].color,
          },
          labels: {
            style: {
              colors: dataGraph.series[0].color,
            },
            formatter: (val, index) => val.toFixed(0),
          },
          title: {
            text: 'Impressions',
            style: {
              color: dataGraph.series[0].color,
              fontSize: '15px',
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        {
          seriesName: dataGraph.series[1].name,
          max: parseInt(dataGraph.max[1]),
          min: parseInt(dataGraph.min[1]),
          forceNiceScale: true,
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: dataGraph.series[1].color,
          },
          labels: {
            style: {
              colors: dataGraph.series[1].color,
            },
            formatter: (val, index) => `${val.toFixed(2)} €`,
          },
          title: {
            text: t('Net Revenue'),
            style: {
              color: dataGraph.series[1].color,
              fontSize: '15px',
            },
          },
          tooltip: {
            enabled: false,
          },
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <Card className="flex-fill">
        <CardBody>
          <CardTitle className="mb-4 float-sm-left">
            {t('Net Revenues')}
          </CardTitle>
          <ButtonGroup size="sm" className="float-sm-right">
            <Button
              color="dark"
              outline
              onClick={() => setSelectAdUnitType('all')}
              active={selectAdUnitType === 'all'}>
              All
            </Button>
            {Object.values(AD_UNIT_TYPES).map((type) => (
              <Button
                key={type}
                color="dark"
                outline
                onClick={() => setSelectAdUnitType(type)}
                active={selectAdUnitType === type}>
                {t(camelize(type, false))}
              </Button>
            ))}
          </ButtonGroup>
          <div className="clearfix"></div>
          {!loading ? (
            <div id="line-chart" className="apex-charts" dir="ltr">
              <ReactApexChart
                series={dailyGraph.series}
                options={dailyGraph.options}
                type="line"
                height={290}
              />
            </div>
          ) : (
            <CenterBox height={290}>
              <i className="bx bx-hourglass bx-spin font-size-32 text-secondary"></i>
            </CenterBox>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default memo(SiteRevenueGraph);
