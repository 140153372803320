const statsTypes = {
  RESET_SITE_NET_REVENUE_STATE: 'RESET_SITE_NET_REVENUE_STATE',
  FETCH_SITE_NET_REVENUES: {
    REQUEST: 'FETCH_SITE_NET_REVENUES.REQUEST',
    SUCCESS: 'FETCH_SITE_NET_REVENUES.SUCCESS',
    FAILURE: 'FETCH_SITE_NET_REVENUES.FAILURE',
  },
};

export default statsTypes;
