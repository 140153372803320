import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import CodeBlock from '../Common/CodeBlock';
import colors from '../CommonStyle/colors';
import { isRtl } from '../../lib/languageTag';

const getScriptCode = (id, langTag) => `<script
  id="speakup-player-script"
  src="https://cdn.speakup.ai/loader.speakup.min.js"
  data-lang="${langTag.split('-')[0]}"
  data-layout="boxed-small"
  data-theme="default"
  data-podcast="${id}"
  async
></script>`;

const styleCode = `<style>
#speakup-player { margin: 0 !important; max-width: none !important; min-height: 130px !important; } #speakup-player:not(#speakup-player:has(.speakup-container))::after { align-items: center; background-color: #fff; border-radius: 4px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12); font-family: sans-serif; content: 'Loading...'; display: flex !important; font-size: 13px; font-weight: 700; line-height: 1; justify-content: center; max-width: 750px; margin: 0 auto; min-height: 130px; text-transform: uppercase; } #speakup-player:not(#speakup-player:has(.speakup-container)) { display: block; } @media (prefers-color-scheme: dark) { #speakup-player:not(#speakup-player:has(.speakup-container))::after { color: #fff; background-color: #000; } }
</style>
<div id="speakup-player"></div>`;

const PreviewPodcast = ({ podcast }) => {
  const { id, title, langTag } = podcast;

  const dir = isRtl(langTag) ? 'rtl' : 'ltr';

  return (
    <Card>
      <CardBody>
        <Row className="text-center m-3">
          <h2 style={{ color: colors.indigo }} dir={dir}>
            {title}
          </h2>
        </Row>
        <Row>
          <Col>
            <h5>Step 1: Add the Player Script</h5>
            <p>{`To get started, you’ll need to add a script to your webpage. Copy and Paste the Script Below into the <head> or <body> section of your webpage before the start of your article:`}</p>
            <CodeBlock code={getScriptCode(id, langTag)} language="html" />
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Step 2: Add the Player Container and the Style</h5>
            <p>
              Place the following container code right before the article text
              begins:
            </p>
            <CodeBlock code={styleCode} language="html" />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default PreviewPodcast;
