import React from 'react';
import {
  ViewButton,
  EditButton,
  AdUnitButton,
  OpenUrlButton,
} from '../Common/DataTable/tableActionButtons';
import { getFilter, getLabel, getSort } from '../Common/DataTable/tableHelpers';
import { ADS_TXT_STATUS } from '../../config/site';

const getNetworkName = (networks, publisherId) => {
  if (!Array.isArray(networks)) return '';
  const network = networks.find(({ publisherIds }) =>
    publisherIds.includes(publisherId),
  );
  return network ? network.name : '';
};

const createTableColumns = (
  openViewModal,
  openEditModal,
  openAdUnitModal,
  publishers,
  networks,
) => [
  {
    title: 'Name',
    name: 'name',
  },
  {
    title: 'Domain',
    name: 'domain',
  },
  {
    title: 'Enable Text Analysis',
    name: 'enableTextAnalysis',
    width: 120,
    getCellValue: (row) => (row.enableTextAnalysis ? 'yes' : 'no'),
    formatterComponent: ({ value }) =>
      value === 'yes' ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-danger font-size-12">NO</span>
      ),
    hidden: true,
  },
  {
    title: 'Publisher',
    name: 'publisherId',
    width: 150,
    getCellValue: (row) => getLabel(row.publisherId, publishers),
  },
  {
    title: 'Network',
    name: 'networkId',
    width: 150,
    getCellValue: (row) => getNetworkName(networks, row.publisherId),
    hidden: true,
  },
  {
    title: 'Setup',
    name: 'setup',
    width: 130,
    formatterComponent: ({ value }) =>
      value === 'completed' ? (
        <span className="badge badge-success font-size-12">
          {value.toUpperCase()}
        </span>
      ) : (
        <span className="badge badge-warning font-size-12">
          {value.toUpperCase()}
        </span>
      ),
  },
  {
    title: 'Publish Podcasts',
    name: 'publishPodcasts',
    width: 120,
    getCellValue: (row) => (row.publishPodcasts ? 'yes' : 'no'),
    formatterComponent: ({ value }) =>
      value === 'yes' ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-danger font-size-12">NO</span>
      ),
    filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
    sorting: (a, b) => getSort(a ? 'yes' : 'no', b ? 'yes' : 'no'),
  },
  {
    title: 'Voices',
    name: 'ttsVoices',
    width: 120,
    getCellValue: (row) =>
      Array.isArray(row.ttsVoices) ? row.ttsVoices.join(' | ') : '',
  },
  {
    title: 'Playlist',
    name: 'renderPlaylist',
    width: 100,
    getCellValue: (row) => (row.renderPlaylist ? 'yes' : 'no'),
    formatterComponent: ({ value }) =>
      value === 'yes' ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-danger font-size-12">NO</span>
      ),
    hidden: true,
  },
  {
    title: 'Vast N.',
    name: 'vastUrls',
    width: 100,
    getCellValue: (row) =>
      Array.isArray(row.vastUrls) ? row.vastUrls.length : 0,
    hidden: true,
  },
  {
    title: 'Seeding Slots',
    name: 'seedingSlots',
    width: 100,
    getCellValue: (row) => row.seedingSlots || 0,
    hidden: true,
  },
  {
    title: 'Improve HTML?',
    name: 'improveHtml',
    width: 100,
    getCellValue: (row) => (row.improveHtml ? 'yes' : 'no'),
    formatterComponent: ({ value }) =>
      value === 'yes' ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-danger font-size-12">NO</span>
      ),
    hidden: true,
  },
  {
    title: 'Ads.txt',
    name: 'adsTxt',
    width: 110,
    formatterComponent: ({ value }) => {
      if (!value) return '';
      const color =
        value === ADS_TXT_STATUS.COMPLETE
          ? 'success'
          : value === ADS_TXT_STATUS.INCOMPLETE
          ? 'warning'
          : 'danger';
      return (
        <span className={`badge badge-${color} font-size-12`}>
          {value.toUpperCase()}
        </span>
      );
    },
  },
  {
    title: 'Active',
    name: 'active',
    width: 100,
    getCellValue: (row) => (row.active ? 'yes' : 'no'),
    formatterComponent: ({ value }) =>
      value === 'yes' ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-danger font-size-12">NO</span>
      ),
  },
  {
    title: 'Action',
    name: 'action',
    width: 200,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        <EditButton row={row} onPress={openEditModal} />
        {/* {openAdUnitModal &&
          row.setup === 'completed' &&
          ((!row.gamAdUnitId && !row.gamAdUnitSeedingId) ||
            !row.adswizzPublisherId) && (
            <AdUnitButton row={row} onPress={openAdUnitModal} />
          )} */}
        {openAdUnitModal &&
          row.setup === 'completed' &&
          ((!row.gamAdUnitId && !row.gamAdUnitSeedingId) ||
            !row.gamTruvidAdUnitId ||
            !row.gamTruvidAdUnitMixedId ||
            !row.adswizzPublisherId) && (
            <AdUnitButton row={row} onPress={openAdUnitModal} />
          )}
        <OpenUrlButton row={row} url={`https://${row.domain}`} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
